/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/recentsearch/v4/recentsearch.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1ActionType = {
    UNSPECIFIED: 'ACTION_TYPE_UNSPECIFIED',
    SEARCH_QUERY: 'ACTION_TYPE_SEARCH_QUERY',
    DELETE_RECENT_QUERY: 'ACTION_TYPE_DELETE_RECENT_QUERY',
    CLICK_SHORTCUT: 'ACTION_TYPE_CLICK_SHORTCUT',
    DELETE_RECENT_SHORTCUT: 'ACTION_TYPE_DELETE_RECENT_SHORTCUT',
    CLEAR_SEARCH_QUERY: 'ACTION_TYPE_CLEAR_SEARCH_QUERY',
    CLEAR_SHORTCUT: 'ACTION_TYPE_CLEAR_SHORTCUT'
} as const;

export type V1ActionType = typeof V1ActionType[keyof typeof V1ActionType];



