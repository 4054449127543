/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/category/v4/category.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V4CategoryKind = {
    UNSPECIFIED: 'CATEGORY_KIND_UNSPECIFIED',
    FLEAMARKET: 'CATEGORY_KIND_FLEAMARKET',
    BUSINESS: 'CATEGORY_KIND_BUSINESS',
    COMMUNITY: 'CATEGORY_KIND_COMMUNITY',
    ALL: 'CATEGORY_KIND_ALL'
} as const;

export type V4CategoryKind = typeof V4CategoryKind[keyof typeof V4CategoryKind];



