/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/fleamarket/v4/fleamarket.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @enum {string}
 */

export const V1DisplayType = {
    UNSPECIFIED: 'DISPLAY_TYPE_UNSPECIFIED',
    SNACKBAR: 'DISPLAY_TYPE_SNACKBAR',
    DIALOG: 'DISPLAY_TYPE_DIALOG',
    NONE: 'DISPLAY_TYPE_NONE'
} as const;

export type V1DisplayType = typeof V1DisplayType[keyof typeof V1DisplayType];



