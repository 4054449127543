/* tslint:disable */
/* eslint-disable */
/**
 * searchserver/http/autocomplete/v4/autocomplete.proto
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: version not set
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RpcStatus } from '../models';
// @ts-ignore
import { V4SearchFeedAutocompleteRequest } from '../models';
// @ts-ignore
import { V4SearchFeedAutocompleteResponse } from '../models';
/**
 * AutocompleteServiceApi - axios parameter creator
 * @export
 */
export const AutocompleteServiceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary 검색웹뷰에서 사용하는 자동완성 API
         * @param {V4SearchFeedAutocompleteRequest} v4SearchFeedAutocompleteRequest 
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autocompleteServiceSearchFeedAutocomplete: async (v4SearchFeedAutocompleteRequest: V4SearchFeedAutocompleteRequest, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelFrom?: string, xSearchOrigin?: string, xUserAgent?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'v4SearchFeedAutocompleteRequest' is not null or undefined
            assertParamExists('autocompleteServiceSearchFeedAutocomplete', 'v4SearchFeedAutocompleteRequest', v4SearchFeedAutocompleteRequest)
            const localVarPath = `/api/v4/feed-autocomplete/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication AccessToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Access-Token", configuration)

            // authentication AuthToken required
            await setApiKeyToObject(localVarHeaderParameter, "X-Auth-Token", configuration)

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['X-Request-Id'] = String(xRequestId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['X-Karrot-Session-Id'] = String(xKarrotSessionId);
            }

            if (xSearchTestGroup !== undefined && xSearchTestGroup !== null) {
                localVarHeaderParameter['X-Search-Test-Group'] = String(xSearchTestGroup);
            }

            if (xSearchFunnelFrom !== undefined && xSearchFunnelFrom !== null) {
                localVarHeaderParameter['X-Search-Funnel-From'] = String(xSearchFunnelFrom);
            }

            if (xSearchOrigin !== undefined && xSearchOrigin !== null) {
                localVarHeaderParameter['X-Search-Origin'] = String(xSearchOrigin);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['X-User-Agent'] = String(xUserAgent);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v4SearchFeedAutocompleteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AutocompleteServiceApi - functional programming interface
 * @export
 */
export const AutocompleteServiceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AutocompleteServiceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary 검색웹뷰에서 사용하는 자동완성 API
         * @param {V4SearchFeedAutocompleteRequest} v4SearchFeedAutocompleteRequest 
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest: V4SearchFeedAutocompleteRequest, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelFrom?: string, xSearchOrigin?: string, xUserAgent?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V4SearchFeedAutocompleteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelFrom, xSearchOrigin, xUserAgent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AutocompleteServiceApi - factory interface
 * @export
 */
export const AutocompleteServiceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AutocompleteServiceApiFp(configuration)
    return {
        /**
         * 
         * @summary 검색웹뷰에서 사용하는 자동완성 API
         * @param {V4SearchFeedAutocompleteRequest} v4SearchFeedAutocompleteRequest 
         * @param {string} [xRequestId] request id
         * @param {string} [xKarrotSessionId] karrot session id
         * @param {string} [xSearchTestGroup] search test group
         * @param {string} [xSearchFunnelFrom] search funnel from
         * @param {string} [xSearchOrigin] search origin
         * @param {string} [xUserAgent] (deprecated) user agent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest: V4SearchFeedAutocompleteRequest, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelFrom?: string, xSearchOrigin?: string, xUserAgent?: string, options?: any): AxiosPromise<V4SearchFeedAutocompleteResponse> {
            return localVarFp.autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelFrom, xSearchOrigin, xUserAgent, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AutocompleteServiceApi - object-oriented interface
 * @export
 * @class AutocompleteServiceApi
 * @extends {BaseAPI}
 */
export class AutocompleteServiceApi extends BaseAPI {
    /**
     * 
     * @summary 검색웹뷰에서 사용하는 자동완성 API
     * @param {V4SearchFeedAutocompleteRequest} v4SearchFeedAutocompleteRequest 
     * @param {string} [xRequestId] request id
     * @param {string} [xKarrotSessionId] karrot session id
     * @param {string} [xSearchTestGroup] search test group
     * @param {string} [xSearchFunnelFrom] search funnel from
     * @param {string} [xSearchOrigin] search origin
     * @param {string} [xUserAgent] (deprecated) user agent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AutocompleteServiceApi
     */
    public autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest: V4SearchFeedAutocompleteRequest, xRequestId?: string, xKarrotSessionId?: string, xSearchTestGroup?: string, xSearchFunnelFrom?: string, xSearchOrigin?: string, xUserAgent?: string, options?: AxiosRequestConfig) {
        return AutocompleteServiceApiFp(this.configuration).autocompleteServiceSearchFeedAutocomplete(v4SearchFeedAutocompleteRequest, xRequestId, xKarrotSessionId, xSearchTestGroup, xSearchFunnelFrom, xSearchOrigin, xUserAgent, options).then((request) => request(this.axios, this.basePath));
    }
}
